.module--module-collection-cards {
  overflow: hidden;
}

.module-collection-cards {
  position: relative;
}

.module-collection-cards.container {
  max-width: var(--max-width, 1920px);
  padding-right: var(--padding);
  padding-left: var(--padding);
  padding-bottom: 50px;
}

@media (max-width: 991px) {
  .module-collection-cards.container {
    padding-bottom: 30px;
  }
}

.module-collection-cards .module-collection-cards__col {
  width: calc(100% / var(--slide-count) - 60px / var(--slide-count));
  max-width: 412.5px;
}

@media (max-width: 1199px) {
  .module-collection-cards .module-collection-cards__col {
    width: calc((100% - 20px) / 2);
    max-width: unset;
  }
}

@media (max-width: 767px) {
  .module-collection-cards .module-collection-cards__col {
    width: calc((100% - 10px) / 2);
  }
}

@media (max-width: 480px) {
  .module-collection-cards .module-collection-cards__col {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .module-collection-cards.module-collection-cards--scroll .module-collection-cards__col {
    flex-shrink: 0;
    width: calc(60% - 5px);
  }
}

@media (max-width: 480px) {
  .module-collection-cards.module-collection-cards--scroll .module-collection-cards__col {
    width: calc(75% - 5px);
  }
}

@media (max-width: 767px) {
  .module-collection-cards.module-collection-cards--single .module-collection-cards__col {
    flex-shrink: 0;
    width: calc(60% - 5px);
  }
}

@media (max-width: 480px) {
  .module-collection-cards.module-collection-cards--single .module-collection-cards__col {
    width: 100%;
  }
}

.module-collection-cards:not(.module-collection-cards--scroll) .module-collection-cards__col--duplicate {
  display: none;
}

.module-collection-cards:not(.container--unless-width) .module-collection-cards__container {
  overflow: hidden;
}

@media (max-width: 767px) {
  .module-collection-cards:not(.container--unless-width) .module-collection-cards__container {
    overflow: visible;
  }
}

.module-collection-cards .swiper:not(.swiper-container-initialized) ~ .swiper-button, .module-collection-cards .swiper:not(.swiper-container-initialized) ~ .swiper-pagination {
  opacity: 0;
}

.module-collection-cards .swiper:not(.swiper-container-initialized) .swiper-wrapper {
  justify-content: center;
  overflow: auto;
}

.module-collection-cards .swiper:not(.swiper-container-initialized) .module-collection-cards__row {
  gap: 20px;
}

@media (max-width: 767px) {
  .module-collection-cards .swiper:not(.swiper-container-initialized) .module-collection-cards__row {
    gap: 10px;
  }
}

.module-collection-cards .swiper:not(.swiper-container-initialized) .module-collection-cards__row::-webkit-scrollbar {
  display: none;
}

.module-collection-cards .swiper:not(.swiper-container-initialized) .module-collection-cards__row {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

@media (max-width: 991px) {
  .module-collection-cards .swiper:not(.swiper-container-initialized) .module-collection-cards__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    row-gap: 20px;
  }
}

.module-collection-cards .module-collection-cards__image-container {
  position: relative;
  top: 0;
  left: 0;
  overflow: hidden;
  display: block;
  width: 100%;
  height: 100%;
  padding-top: 140.6%;
}

.module-collection-cards .module-collection-cards__image-container .module-collection-cards__image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition-timing-function: ease;
  transition-property: transform;
  transition-duration: 1.2s;
  object-fit: cover;
}

.module-collection-cards .module-collection-cards__image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.module-collection-cards .module-collection-cards__image-container:focus-visible .module-collection-cards__image {
  opacity: 0.6;
}

@media (-moz-touch-enabled: 0), (hover: hover) {
  .module-collection-cards .module-collection-cards__image-container:hover .module-collection-cards__image {
    transform: scale(1.15);
  }
  .module-collection-cards .module-collection-cards__image-container:hover::after {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.25) 100%);
  }
}

.module-collection-cards .module-collection-cards__info {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1;
  width: 100%;
  padding: 30px;
}

@media (max-width: 767px) {
  .module-collection-cards .module-collection-cards__info {
    padding: 20px;
  }
}

.module-collection-cards .module-collection-cards__info.module-collection-cards__info--top-left {
  top: 0;
  text-align: left;
}

.module-collection-cards .module-collection-cards__info.module-collection-cards__info--top-middle {
  top: 0;
  text-align: center;
}

.module-collection-cards .module-collection-cards__info.module-collection-cards__info--top-right {
  top: 0;
  text-align: right;
}

.module-collection-cards .module-collection-cards__info.module-collection-cards__info--center-left {
  top: 50%;
  text-align: left;
  transform: translateY(-50%);
}

.module-collection-cards .module-collection-cards__info.module-collection-cards__info--center-center {
  top: 50%;
  text-align: center;
  transform: translateY(-50%);
}

.module-collection-cards .module-collection-cards__info.module-collection-cards__info--center-right {
  top: 50%;
  text-align: right;
  transform: translateY(-50%);
}

.module-collection-cards .module-collection-cards__info.module-collection-cards__info--bottom-left {
  bottom: 0;
  text-align: left;
}

.module-collection-cards .module-collection-cards__info.module-collection-cards__info--bottom-center {
  bottom: 0;
  text-align: center;
}

.module-collection-cards .module-collection-cards__info.module-collection-cards__info--bottom-right {
  bottom: 0;
  text-align: right;
}

.module-collection-cards .module-collection-cards__heading,
.module-collection-cards .module-collection-cards__text {
  color: #ffffff;
}

.module-collection-cards .module-collection-cards__heading {
  font-family: "GT Super Ds", sans-serif;
  font-size: 30px;
  line-height: 1.25;
}

@media (max-width: 575px) {
  .module-collection-cards .module-collection-cards__heading {
    font-size: 23px;
  }
}

.module-collection-cards .module-collection-cards__text {
  position: relative;
  display: inline-block;
  margin-top: 6px;
  font-family: "Acumin Pro", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.43;
  letter-spacing: 0.2em;
}

@media (max-width: 767px) {
  .module-collection-cards .module-collection-cards__text {
    margin-top: 6px;
  }
}

@media (max-width: 575px) {
  .module-collection-cards .module-collection-cards__text {
    margin-top: 0;
    font-size: 10px;
  }
}

.module-collection-cards .module-collection-cards__text::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ffffff;
}

.module-collection-cards .swiper-pagination {
  width: 100%;
  bottom: 0;
  margin-top: 10px;
  position: relative;
  z-index: 3;
}

.module-collection-cards .swiper-pagination .swiper-pagination-bullet {
  opacity: 1;
  width: 8px;
  height: 8px;
  margin-right: 8px;
  border: 2px solid #2b2b2b;
  background-color: transparent;
  transition-property: background-color, opacity;
  transition-duration: 0.3s, 0.2s;
  transition-timing-function: ease-in-out;
}

.module-collection-cards .swiper-pagination .swiper-pagination-bullet:focus-visible {
  outline: 2px solid #2b2b2b;
  outline-offset: 3px;
}

@media (-moz-touch-enabled: 0), (hover: hover) {
  .module-collection-cards .swiper-pagination .swiper-pagination-bullet:hover {
    opacity: 0.7;
  }
}

.module-collection-cards .swiper-pagination .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.module-collection-cards .swiper-pagination .swiper-pagination-bullet-active {
  background-color: #2b2b2b;
}

.module-collection-cards .swiper-button {
  top: 48.2%;
  width: 20px;
  height: 60px;
}

.module-collection-cards .swiper-button svg {
  width: 23px;
  height: 60px;
}

.module-collection-cards .swiper-button.brands-slider__button--prev {
  left: 3px;
}

.module-collection-cards .swiper-button.brands-slider__button--next {
  right: 3px;
}

.module-collection-cards .swiper-button::after {
  content: none;
}

.module-collection-cards__container .swiper-button-next:after,
.module-collection-cards__container .swiper-button-prev:after {
  color: #2b2b2b;
  max-height: 28px;
  max-width: 15px;
  height: 28px;
  width: 15px;
  font-size: 28px;
}

.module-collection-cards__container .swiper-button-next {
  right: 10px;
  justify-content: end;
  width: 50px;
  height: 60px;
  padding-right: 20px;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .module-collection-cards__container .swiper-button-next {
    display: none;
  }
}

.module-collection-cards__container .swiper-button-prev {
  left: 10px;
  justify-content: start;
  width: 50px;
  height: 60px;
  padding-left: 20px;
  transform: translateY(-50%);
}

@media (max-width: 991px) {
  .module-collection-cards__container .swiper-button-prev {
    display: none;
  }
}

.container--unless-width .swiper-button-prev {
  top: 25px;
  height: 87%;
  left: 0;
  padding: 0 30px;
  width: auto;
  transform: translateY(0);
}

.container--unless-width .swiper-button-next {
  top: 25px;
  height: 87%;
  right: 0;
  padding: 0 30px;
  width: auto;
  transform: translateY(0);
}

.module-collection-cards .swiper-button-prev.swiper-button-disabled,
.module-collection-cards .swiper-button-next.swiper-button-disabled {
  pointer-events: unset;
}